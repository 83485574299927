<template>
  <component
    :is="tag"
    :class="['editorial-mosaic', { 'section-inner': tag !== 'section' }]"
  >
    <div v-if="isDesktop && blok.list?.length" class="editorial-mosaic-list">
      <StoryblokComponent
        v-for="item in blok.list"
        :key="item._uid"
        :blok="item"
      />
    </div>
    <div v-if="!isDesktop && blok.list?.length" class="carousel-container">
      <GenericCarousel
        v-if="blok.list?.length > 1"
        :items="blok.list"
        slides-per-view="auto"
        :space-between="14"
        class="carousel-navigation-bottom"
      />
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok } = toRefs(props); // eslint-disable-line
const isDesktop = inject("isDesktop");
</script>

<style lang="scss" scoped>
.editorial-mosaic {
  &-list {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-gap: 0.83rem;
    @include for-tablet-landscape-up {
      flex-wrap: wrap;
      :deep(.editorial-attribute) {
        min-width: auto;
        grid-column: span 6;
      }
    }
  }
}

section.editorial-mosaic {
  & > .editorial-mosaic-list,
  & > .carousel-container {
    grid-column: 2 / -2;
  }
}
</style>

<style lang="scss">
.media-text-cta.is-special {
  width: 18rem;
  @include for-tablet-landscape-up {
    width: auto;
    grid-column: span 6;
  }
  .simple-button {
    margin-top: 0;
  }
}
.editorial-section .editorial-mosaic .media-text-cta.is-special.parent-bg {
    @include pair-3;
    --parent-bg: var(--solid-03);
  }
.alternate .editorial-section .editorial-mosaic .media-text-cta.is-special.parent-bg {
  @include pair-7;
  --parent-bg: var(--solid-07);
}
.alternate .editorial-mosaic {
  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      background-color: var(--solid-03);
    }
  }
}
</style>
